
import Vue, { PropType } from "vue";

import MyPreview from "@/components/MyPreview.vue";

import { Garbage } from "@/interfaces";

export interface DataType {
  content: string;
  url: string;
  valid: boolean;
}

export default Vue.extend({
  components: {
    MyPreview
  },
  props: {
    garbage: {
      type: Object as PropType<Garbage>,
      required: true
    }
  },
  data(): DataType {
    return {
      url: "",
      content: "",
      valid: false
    };
  },
  computed: {
    rules() {
      return {
        url: [(v: string) => !!v || "URLは必須です"],
        content: [(v: string) => !!v || "コンテンツは必須です"]
      };
    }
  },
  mounted() {
    this.content = this.garbage.content;
    this.url = this.garbage.url;
  },
  methods: {
    submit() {
      this.$emit("save", {
        url: this.url,
        content: this.content
      });
    }
  }
});
