
import Vue, { PropType } from "vue";

import http from "@/http";

import { Building, Garbage } from "@/interfaces";

import MyEditor from "@/components/garbage/MyEditor.vue";

export interface DataType {
  garbage?: Garbage;
}

export default Vue.extend({
  components: {
    MyEditor
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  data(): DataType {
    return {
      garbage: undefined
    };
  },
  mounted() {
    if (this.building.garbage) {
      this.garbage = this.building.garbage;
    } else {
      this.garbage = {
        content: "",
        url: "",
        garbage_stations: [],
        building: this.building,
        building_id: this.building.id
      };
    }
  },
  methods: {
    async save(e: Garbage) {
      if (this.garbage?.id) {
        await this.update(e);
      } else {
        await this.store(e);
      }

      const url = `/building/${this.building.id}/garbage`;

      await this.$router.push(url);
    },
    async store(garbage: Garbage) {
      const url = `buildings/${this.building.id}/garbages`;

      const { data } = await http.post(url, {
        content: garbage.content,
        url: garbage.url
      });
    },
    async update(garbage: Garbage) {
      if (this.building.garbage == null) {
        return;
      }
      const url = `garbages/${this.building.garbage.id}`;

      const { data } = await http.put(url, {
        content: garbage.content,
        url: garbage.url
      });
    }
  }
});
